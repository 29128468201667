@import "../variables.scss";

/*******************/
// Used to overide amplify styling for authenticator
/********************/

@import "@aws-amplify/ui-react/styles.css";


//root variables
:root {
//   --amplify-fonts-default-variable: "Open Sans";
//   --amplify-fonts-default-static: "Open Sans";
//   --amplify-colors-brand-primary-10: red;
//   --amplify-colors-brand-primary-20: yellow;
//   --amplify-colors-brand-primary-40: green;
//   --amplify-colors-brand-primary-60: purple;
//   --amplify-colors-brand-primary-80: $success!important;
//   --amplify-colors-brand-primary-90: $secondary!important;
//   --amplify-colors-brand-primary-100: brown;
//   --amplify-colors-brand-secondary-10: var(--amplify-colors-neutral-10);
//   --amplify-colors-brand-secondary-20: var(--amplify-colors-neutral-20);
//   --amplify-colors-brand-secondary-40: var(--amplify-colors-neutral-40);
//   --amplify-colors-brand-secondary-60: var(--amplify-colors-neutral-60);
//   --amplify-colors-brand-secondary-80: var(--amplify-colors-neutral-80);
//   --amplify-colors-brand-secondary-90: var(--amplify-colors-neutral-90);
//   --amplify-colors-brand-secondary-100: var(--amplify-colors-neutral-100);
//   --amplify-colors-radii-small: 0;
//   --amplify-colors-radii-medium: 2px;
//   --amplify-colors-radii-large: 4px;
//   --amplify-colors-border-primary: var(--amplify-colors-neutral-40);
//   --amplify-colors-border-secondary: var(--amplify-colors-neutral-20);
//   --amplify-colors-border-tertiary: var(--amplify-colors-neutral-10);
//   --amplify-colors-background-secondary: var(--amplify-colors-brand-primary-10);
//   --amplify-components-tabs-item-border-color: var(--amplify-colors-neutral-60);

//   --amplify-radii-small: 0;
//   --amplify-radii-medium: 0;
//   --amplify-radii-large: 0;
//   --amplify-space-small: 1rem;
//   --amplify-space-medium: 1.5rem;
//   --amplify-space-large: 2rem;
//   --amplify-border-widths-small: 2px;
//   --amplify-border-widths-medium: 4px;
//   --amplify-border-widths-large: 8px;
  --amplify-colors-brand-primary-100: #B2D953;
  --amplify-components-tabs-item-active-color: #B2D953;
  --amplify-components-tabs-item-active-border-color: #B2D953;
  --amplify-components-tabs-item-hover-color: #B2D953c9;
  --amplify-components-tabs-item-color: #B2D953;
  --amplify-components-button-focus-border-color: #B2D953;
}

body {
  margin: 0;
  padding: 0;
}

div[data-amplify-router]{
    background-color: var(--bg-color-contrast1)!important;
    font-family: $font-family-sans-serif;
    border: none!important;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
}

div[data-amplify-router-content]{
    background-color: var(--bg-color-contrast1);
    color: var(--text-color);
}

div[data-amplify-authenticator]{
    margin-top: 5rem;
}

.amplify-label,
.amplify-text,
.amplify-heading {
    color: var(--text-color);
}

.amplify-field__show-password {
    color: #fff;
    opacity: 0.9;
    background-color: $secondary;
    border: none;
}
.amplify-field__show-password:hover, .amplify-field__show-password:focus {
    color: #fff;
    opacity: 0.8;
    background-color: $secondary;
    border: none;
}
.amplify-field__show-password:active {
    color: #fff;
    opacity: 1;
    background-color: $secondary;
    border: none;
}

.amplify-button {
    color: $secondary;
    background-color: none!important;
}
.amplify-button:hover, .amplify-button:focus, .amplify-button:active {
    opacity: 0.8;
    color: $white;
    background-color: $secondary;
    border-color: $secondary;
    box-shadow: none;
}
.amplify-button:focus {
    filter: brightness(85%);
}

.amplify-button--link {
    color: $secondary;
    background-color: none!important;
    border: none!important;
}

.amplify-button--link:hover, .amplify-button--link:focus, .amplify-button--link:active {
    color: $secondary;
    background-color: var(--bg-color-contrast1);
    border: none!important;
}

.amplify-button--primary {
    margin-top: 3rem;
    color: #fff;
    background-color: $secondary;
}
.amplify-button--primary:hover, .amplify-button--primary:focus {
    color: #fff;
    background-color: $secondary;
}
.amplify-button--primary:active {
    color: #fff;
    background-color: $secondary;
    border: none!important;
}

.amplify-tabs-item[data-state=active] {
    background-color: $primary;
    color: #fff;
    border-color: $secondary;
}
.amplify-tabs-item[data-state=active]:hover {
    opacity: 0.8;
}
.amplify-tabs-item[data-state=active]:active {
    color: #fff;
    background-color: $secondary;
    border-color: $secondary;
}

.amplify-tabs-item[data-state=inactive] {
    background-color: var(--bg-color-contrast1);
    color: $primary;
    border-color: var(--bg-color-contrast3);
}
.amplify-tabs-item:hover[data-state=inactive] {
    color: $primary;
    background-color: var(--bg-color-contrast2);
}
.amplify-tabs-item:active[data-state=inactive] {
    color: $primary;
    background-color: var(--bg-color-contrast2);
    border-color: var(--bg-color-contrast3);
}

.amplify-input{
    background-color: var(--bg-color)!important;
    color: var(--text-color)!important;
    border: 0px solid;
}

form[data-amplify-authenticator-confirmsignup]{
    display: none;
}

.amplify-tabs__item[aria-selected=false]{
    background-color: var(--bg-color-contrast2);
    color: var(--text-color);
    border-color: var(--bg-color-contrast3);
}
.amplify-tabs__item[aria-selected=false]:hover{
    background-color: var(--bg-color-contrast2);
    color: var(--text-color);
    border-color: var(--bg-color-contrast3);
    opacity: 0.8;
}

.amplify-field__show-password{
    color: $primary;
    background-color: var(--bg-color-contrast2);
}


